import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import About from './pages/About';
import Contact from './pages/Contact';
import Home from './pages/Home';
import Services from './pages/Service';
import Servicedetails from './components/Servicedetails';
import Gallery from './pages/Gallery';
import Error from './components/Error';
import GalleryDetails from './components/GalleryDetails';

function App() {



  return (
    <Router>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Gallery" element={<Gallery />} />
        <Route path="/GalleryDetails/:folderName" element={<GalleryDetails />} />
        <Route path="/Servicedetails" element={<Servicedetails />} />
        <Route path="/Servicedetails/:productName" element={<Servicedetails />} />
        <Route path="*" element={<Error />} />

      </Routes>
      <Footer />
    </Router>

  );
}
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default App;