import React from 'react';
import fileNames, { folderNamesSet } from '../model.js';
import { NavLink } from 'react-router-dom';

function Gallerys() {
  return (
    <>
      <div className="gallery">
        <div className="section-header">
          {/* <span>Our Gallery</span> */}
          <h2>Our Gallery</h2>

        </div>
        <section className="photo-albumns-container">
          {Array.from(folderNamesSet).map((folderName, index) => {
            // Assuming your image files have a specific naming convention (e.g., folderName_image_0.jpg, folderName_image_1.jpg, ...)
            const filteredFileNames = fileNames.filter(fileName => fileName.startsWith(`/${folderName}/`));
            const imageUrl = `/assets/img/gallery/${filteredFileNames[0] || ''}`; // Use the first item or empty string if no match found

            return (
              <div
                key={index}
                style={{ backgroundImage: `url(${imageUrl})` }}
                className="photo-album-components"
              >
                <NavLink to={`/GalleryDetails/${folderName}`}>{folderName}</NavLink>
                <span className="gallery-album-heading">{folderName}</span>
              </div>
            );
          })}
        </section>
      </div>
    </>
  );
}

export default Gallerys;

