import React from 'react';
// jitender Kumar Managing director
// Mukesh Kumar
// Project manager
// Vikas Kumar Mishra
// Finance/ Account
// Roshan Kumar
// Store incharge
// Rishikesh kumar
// Technician
// Dharmender Kumar
// Technician
function Team() {
  const teamMembers = [
    {
      name: 'Jitender Kumar',
      position: 'Managing director',
      imgSrc: 'assets/img/team/Jitender Kumar.jpg',
      socialLinks: [
        { platform: 'twitter', link: '/' },
        { platform: 'facebook', link: '/' },
        { platform: 'instagram', link: '/' },
        { platform: 'linkedin', link: '/' },
      ],
      description: 'Velit aut quia',
    },
    {
      name: 'Mukesh Kumar',
      position: 'Project manager',
      imgSrc: 'assets/img/team/Mukesh Kumar.jpg',
      socialLinks: [
        { platform: 'twitter', link: '/' },
        { platform: 'facebook', link: '/' },
        { platform: 'instagram', link: '/' },
        { platform: 'linkedin', link: '/' },
      ],
      description: 'Quo esse repellendus.',
    },
    {
      name: 'Vikas Kumar Mishra',
      position: 'Accountant',
      imgSrc: 'assets/img/team/Vikas Kumar Mishra.jpg',
      socialLinks: [
        { platform: 'twitter', link: '/' },
        { platform: 'facebook', link: '/' },
        { platform: 'instagram', link: '/' },
        { platform: 'linkedin', link: '/' },
      ],
      description: 'Vero omnis enim consequatur.',
    },
    {
      name: 'Roshan Kumar',
      position: 'Store incharge',
      imgSrc: 'assets/img/team/Roshan Kumar.jpg',
      socialLinks: [
        { platform: 'twitter', link: '/' },
        { platform: 'facebook', link: '/' },
        { platform: 'instagram', link: '/' },
        { platform: 'linkedin', link: '/' },
      ],
      description: 'Rerum voluptate non adipisci',
    },
  ];

  return (
    <section id="team" className="team">
      <div className="container" data-aos="fade-up">
        
        <div className="section-header">
          {/* <span>Our Team</span> */}
          <h2>Our Team</h2>

        </div>
        <div className="row gy-4">
          {teamMembers.map((member, index) => (
            <div
              key={index}
              className="col-lg-3 col-md-6 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={index * 100 + 100}
            >
              <div className="member">
                <div className="member-img">
                  <img src={member.imgSrc} className="img-fluid" alt="" />
                  {/* <div className="social">
                    {member.socialLinks.map((link, index) => (
                      <a key={index} href={link.link}>
                        <i className={`bi bi-${link.platform}`} />
                      </a>
                    ))}
                  </div> */}
                </div>
                <div className="member-info">
                  <h4>{member.name}</h4>
                  <span>{member.position}</span>
                  {/* <p>{member.description}</p> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Team;