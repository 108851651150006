import React from 'react';

function SurveillanceSystemDetails() {
  return (
    <>

      <div className="col-lg-12">

        <h3>Surveillance System</h3>
        <p>
          Our Surveillance System provides comprehensive security monitoring for your premises. With advanced camera technology and intelligent video analytics, you can keep a close eye on your property and enhance safety and security.
        </p>
        <ul>
          <li><i className="bi bi-check-circle"></i> <span>High-definition cameras with clear video footage.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Wide coverage and adjustable viewing angles.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Night vision capabilities for round-the-clock monitoring.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Remote access and mobile viewing for real-time monitoring.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Motion detection and alerts for suspicious activities.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Integration with other security systems.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Easy scalability to accommodate growing needs.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Secure data storage and backup options.</span></li>
        </ul>
      </div>

    </>
  );
}

export default SurveillanceSystemDetails;

