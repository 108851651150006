import React from 'react';
// import Breadcrumb from '../components/Breadcrumb';
import Servicesection from '../components/Servicesection';
// import Testimonials from '../components/Testimonials';

function Service() {


  return (
    <section>
      {/* <Breadcrumb /> */}


      <Servicesection displayAll={true} />
      {/* <Testimonials /> */}
    </section>
  );
}

export default Service;
