import React from 'react';

function VisitorManagementSystem() {
  return (
    <>


      <div className="col-lg-12" data-aos="fade-left">
        <h2>Visitor Management System</h2>
        <p>
          Our Visitor Management System is a comprehensive solution designed to streamline and enhance the visitor registration process. With advanced features and user-friendly interface, our system provides efficient management of visitors, ensuring better security and improved visitor experience.
        </p>
        <ul>
          <li><i className="bi bi-check-circle"></i> <span>Efficient visitor registration and check-in process.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Real-time tracking of visitors within the premises.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Customizable visitor badges for easy identification.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Integration with access control systems.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Visitor pre-registration and self-service kiosk options.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Automated visitor notifications to hosts.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Visitor data management and reporting.</span></li>
        </ul>
        <p>
          Our Visitor Management System offers a reliable and scalable solution for organizations of all sizes, helping them enhance security, improve visitor experience, and streamline administrative processes.
        </p>
      </div>

    </>
  );
}

export default VisitorManagementSystem;
