import React from 'react';
import { NavLink } from 'react-router-dom';

function Footer() {
  const footerLinks = [
    {
      title: 'Useful Links',
      links: [
        { id: 1, title: 'Home', URL_: '/' },
        { id: 2, title: 'About us', URL_: '/About' },
        { id: 3, title: 'Services', URL_: '/Services' },
        { id: 4, title: 'Contact us', URL_: '/Contact' },
      ],
    },

    {
      title: 'Our Services',
      links: [
        {
          id: 6,
          title: 'Surveillance System', URL_: `/Servicedetails/${'Surveillance System'.toLowerCase().replace(/ /g, '-')}`
        },
        {
          id: 7,
          title: 'Public Address System', URL_: `/Servicedetails/${'Public Address System'.toLowerCase().replace(/ /g, '-')}`
        },
        {
          id: 8,
          title: 'Fire Safety and Security System', URL_: `/Servicedetails/${'Fire Safety and Security System'.toLowerCase().replace(/ /g, '-')}`
        },
        // {
        //   id: 9,
        //   title: 'Access control & Attendance Management System', URL_: `/Servicedetails/${service.title.toLowerCase().replace(/ /g, '-')}`
        // },
        // {
        //   id: 10,
        //   title: 'Audio/Visual and IP Telephone System', URL_: '/'
        // },
        // {
        //   id: 11,

        //   title: 'Entry Management & IoT Solution',
        //   URL_: '/'
        // },
        // {
        //   id: 12,
        //   title: 'Guard Tour Patrol System', URL_: '/'
        // },
        // {
        //   id: 13,
        //   title: 'COVID-19 Safety & Security Products', URL_: '/'
        // },
        // {
        //   id: 14,
        //   title: 'Visitor Management System', URL_: '/'
        // },
        {
          id: 14,
          title: 'Network Security System', URL_: `/Servicedetails/${'Network Security System'.toLowerCase().replace(/ /g, '-')}`
        },

      ],
    },
  ];

  return (
    <footer id="footer" className="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-5 col-md-12 footer-info">
              <a href="index.html" className="logo d-flex align-items-center">
                <img src="/assets/img/lologo.png" alt="" />
                <span>JK Fire Protection Systems</span>
              </a>
              <p>
                JK FIRE PROTECTION SYSTEMS has earned a highly esteemed reputation within the industry and has emerged as the trusted and professional ELV Services Company in North India. We proudly serve as your smart integration partner, dedicated to building robust physical security solutions.
              </p>
              {/* <div className="social-links mt-3">
                <a href="/" className="twitter">
                  <i className="bi bi-twitter"></i>
                </a>
                <a href="/" className="facebook">
                  <i className="bi bi-facebook"></i>
                </a>
                <a href="/" className="instagram">
                  <i className="bi bi-instagram"></i>
                </a>
                <a href="/" className="linkedin">
                  <i className="bi bi-linkedin"></i>
                </a>
              </div> */}
            </div>

            {footerLinks.map((item) => (
              <div className="col-lg-2 col-6 footer-links" key={item.title}>
                <h4>{item.title}</h4>
                <ul>
                  {item.links.map((link) => (
                    <li key={link.id}>
                      <i className="bi bi-chevron-right"></i>
                      <NavLink to={link.URL_}>{link.title}</NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            ))}

            <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
              <h4>Contact Us</h4>
              <p>
                RC 437/2 Kala enclave khora colony, <br />
                ghaziabad, Uttar Pradesh, 201309<br />
                INDIA  <br />

                <br />
                <strong>Phone:</strong> +91-9818755113<span></span> / +91-8585998726<br />
                <strong>Email:</strong> Info@jkfps.com<br />
              </p>
            </div>
          </div>

          <div className="copyright">
            &copy; Copyright <strong><span>JK Fire Protection Systems</span></strong>.
            All Rights Reserved
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
