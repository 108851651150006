import React, { useState } from 'react';

import Carousel from 'react-owl-carousel';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { useParams } from 'react-router-dom';
import Sidebar from './Sidebar';
import Breadcrumb from './Breadcrumb';
import AccessControlDetails from './serviceDetailPages/AccessControlDetails'
import AVIPTelephoneDetails from './serviceDetailPages/AVIPTelephoneDetails'
import FireHydrantAndSprinklerSystems from './serviceDetailPages/FireHydrantAndSprinklerSystems'
import FireSafetyAndSecuritySystem from './serviceDetailPages/FireSafetyAndSecuritySystem'
import FM200Details from './serviceDetailPages/FM200Details'
import NetworkSecuritySystemDetails from './serviceDetailPages/NetworkSecuritySystemDetails'
import PublicAddressSystemDetails from './serviceDetailPages/PublicAddressSystemDetails'
import SurveillanceSystemDetails from './serviceDetailPages/SurveillanceSystemDetails'
import VisitorManagementSystem from './serviceDetailPages/VisitorManagementSystem'

function Servicedetails() {
  const { productName } = useParams(); // Extract the productId from the URL

  const [activeCategory, setActiveCategory] = useState(null);

  const categories = [
    { id: 1, name: 'Surveillance System', },
    { id: 2, name: 'Public Address System', },
    { id: 3, name: 'Fire Safety and Security System', },
    { id: 4, name: 'Access control System', },
    { id: 5, name: 'Audio-Visual and IP Telephone System', },
    { id: 6, name: 'Fire hydrants and Sprinklers systems', },
    { id: 7, name: 'FM-200 gas based Fire Suppression systems', },
    // { id: 8, name: '', },
    { id: 8, name: 'Visitor Management System', },
    { id: 9, name: 'Network Security System', },
    // Add more categories as needed
  ];

  const products = [

    { id: 1, image: ['/assets/img/servicesdp/Surveillance System.jpg', '/assets/img/jk/Surveillance System.jpeg'], name: 'Surveillance System', category: 1, lik: 'SurveillanceSystemDetails' },
    { id: 2, image: ['/assets/img/servicesdp/Public Address System.jpg', '/assets/img/jk/Public Address System.jpg'], name: 'Public Address System', category: 2, lik: 'PublicAddressSystemDetails' },
    { id: 3, image: ['/assets/img/servicesdp/Fire Safety and Security System.png', '/assets/img/jk/Fire Safety and Security System.jpeg'], name: 'Fire Safety and Security System', category: 3, lik: 'FireSafetyAndSecuritySystem' },
    { id: 4, image: ['/assets/img/servicesdp/Access control.jpg', '/assets/img/jk/Access control.png'], name: 'Access control System', category: 4, lik: 'AccessControlDetails' },
    { id: 5, image: ['/assets/img/servicesdp/Audio-Visual and IP Telephone System.jpg', '/assets/img/jk/Audio-Visual and IP Telephone System.jpg'], name: 'Audio-Visual and IP Telephone System', category: 5, lik: 'AVIPTelephoneDetails' },
    { id: 6, image: ['/assets/img/servicesdp/Fire hydrants and Sprinklers systems.PNG', '/assets/img/jk/Fire hydrants and Sprinklers systems.jpg'], name: 'Fire hydrants and Sprinklers systems', category: 6, lik: 'FireHydrantAndSprinklerSystems' },
    { id: 7, image: ['/assets/img/servicesdp/FM-200 gas based Fire Suppression systems.PNG', '/assets/img/jk/FM-200 gas based Fire Suppression systems.jpg'], name: 'FM-200 gas based Fire Suppression systems', category: 7, lik: 'FM200Details' },
    { id: 8, image: ['/assets/img/servicesdp/Visitor Management System.png', '/assets/img/jk/Visitor Management System.png'], name: 'Visitor Management System', category: 8, lik: 'VisitorManagementSystem' },
    { id: 9, image: ['/assets/img/servicesdp/Network Security System.jpg', '/assets/img/jk/Network Security System.jpg'], name: 'Network Security System', category: 9, lik: 'NetworkSecuritySystemDetails' },

    // Add more products with category assignments
  ];

  const carouselOptions = {
    loop: true,
    // margin: 10,
    responsive: {

      320: {
        items: 1,
        margin: 80,
      },
      1000: {
        items: 2,
        margin: 120,
      }
    },
    autoplay: true, // Enable autoplay
    autoplayTimeout: 4000, // Autoplay interval in milliseconds
    autoplayHoverPause: true, // Pause autoplay on hover
    dots: true, // Show pagination dots
  };

  const filteredProducts = activeCategory
    ? products.filter((product) => product.category === activeCategory)
    : products;

  // Find the selected product based on the productName
  const selectedProduct = filteredProducts.find(
    (product) =>
      product.name &&
      product.name.toLowerCase().replace(/ /g, '-') === productName?.toLowerCase().replace(/ /g, '-')
  );

  return (
    <>
      <Breadcrumb />
      <section id="service-details" className="service-details">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
            <div className="col-lg-4">
              <div className="services-list">
                <Sidebar
                  categories={categories}
                  activeCategory={activeCategory}
                  setActiveCategory={setActiveCategory}
                />
              </div>
            </div>

            {selectedProduct ? (
              <div className="col-lg-8" key={selectedProduct.name}>
                <Carousel className="owl-carousel" {...carouselOptions}>
                  {selectedProduct.image.map((image, index) => (
                    <div key={index} className="swiper-slide">
                      <img src={image} style={{ marginBottom: '1rem' }} className="img-fluid" alt="" />
                    </div>
                  ))}
                </Carousel>
                {(selectedProduct.lik === 'AccessControlDetails' && <AccessControlDetails />) ||
                  (selectedProduct.lik === 'NetworkSecuritySystemDetails' && <NetworkSecuritySystemDetails />) ||
                  (selectedProduct.lik === 'VisitorManagementSystem' && <VisitorManagementSystem />) ||
                  (selectedProduct.lik === 'FM200Details' && <FM200Details />) ||
                  (selectedProduct.lik === 'FireHydrantAndSprinklerSystems' && <FireHydrantAndSprinklerSystems />) ||
                  (selectedProduct.lik === 'AVIPTelephoneDetails' && <AVIPTelephoneDetails />) ||
                  (selectedProduct.lik === 'FireSafetyAndSecuritySystem' && <FireSafetyAndSecuritySystem />) ||
                  (selectedProduct.lik === 'SurveillanceSystemDetails' && <SurveillanceSystemDetails />) ||
                  (selectedProduct.lik === 'PublicAddressSystemDetails' && <PublicAddressSystemDetails />)
                }
              </div>
            ) : (
              <div className="col-lg-8">
                {filteredProducts.map(product => (
                  <div className="col-lg-8" key={product.name}>
                    <Carousel className="owl-carousel" {...carouselOptions}>
                      {product.image.map((image, index) => (
                        <div key={index} className="swiper-slide">
                          <img src={image} style={{ marginBottom: '1rem' }} className="img-fluid" alt="" />
                        </div>
                      ))}
                    </Carousel>
                    {(product.lik === 'AccessControlDetails' && <AccessControlDetails />) ||
                      (product.lik === 'NetworkSecuritySystemDetails' && <NetworkSecuritySystemDetails />) ||
                      (product.lik === 'VisitorManagementSystem' && <VisitorManagementSystem />) ||
                      (product.lik === 'FM200Details' && <FM200Details />) ||
                      (product.lik === 'FireHydrantAndSprinklerSystems' && <FireHydrantAndSprinklerSystems />) ||
                      (product.lik === 'AVIPTelephoneDetails' && <AVIPTelephoneDetails />) ||
                      (product.lik === 'FireSafetyAndSecuritySystem' && <FireSafetyAndSecuritySystem />) ||
                      (product.lik === 'SurveillanceSystemDetails' && <SurveillanceSystemDetails />) ||
                      (product.lik === 'PublicAddressSystemDetails' && <PublicAddressSystemDetails />)
                    }
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </section>
      {/* {selectedProduct ? (
        <div className="col-lg-8" key={selectedProduct.name}>
          <img
            src={selectedProduct.image}
            alt="log"
            className="img-fluid services-img"
          />
          <h3>{selectedProduct.name}</h3>
          <p>{selectedProduct.desc}</p>
          {(selectedProduct.lik === 'AccessControlDetails' && <AccessControlDetails />) ||
            (selectedProduct.lik === 'NetworkSecuritySystemDetails' && <NetworkSecuritySystemDetails />) ||
            (selectedProduct.lik === 'VisitorManagementSystem' && <VisitorManagementSystem />) ||
            (selectedProduct.lik === 'FM200Details' && <FM200Details />) ||
            (selectedProduct.lik === 'FireHydrantAndSprinklerSystems' && <FireHydrantAndSprinklerSystems />) ||
            (selectedProduct.lik === 'AVIPTelephoneDetails' && <AVIPTelephoneDetails />) ||
            (selectedProduct.lik === 'FireSafetyAndSecuritySystem' && <FireSafetyAndSecuritySystem />) ||
            (selectedProduct.lik === 'SurveillanceSystemDetails' && <SurveillanceSystemDetails />) ||
            (selectedProduct.lik === 'PublicAddressSystemDetails' && <PublicAddressSystemDetails />)
          }
        </div>
      ) : (
        <div className="col-lg-8">
          {filteredProducts.map(product => (
            <div className="col-lg-8" key={product.name}>
              <img src={product.image} alt="log" className="img-fluid services-img" />
              <h3> {product.name}</h3>
              <p>{product.desc}</p>
              {(product.lik === 'AccessControlDetails' && <AccessControlDetails />) ||
                (product.lik === 'NetworkSecuritySystemDetails' && <NetworkSecuritySystemDetails />) ||
                (product.lik === 'VisitorManagementSystem' && <VisitorManagementSystem />) ||
                (product.lik === 'FM200Details' && <FM200Details />) ||
                (product.lik === 'FireHydrantAndSprinklerSystems' && <FireHydrantAndSprinklerSystems />) ||
                (product.lik === 'AVIPTelephoneDetails' && <AVIPTelephoneDetails />) ||
                (product.lik === 'FireSafetyAndSecuritySystem' && <FireSafetyAndSecuritySystem />) ||
                (product.lik === 'SurveillanceSystemDetails' && <SurveillanceSystemDetails />) ||
                (product.lik === 'PublicAddressSystemDetails' && <PublicAddressSystemDetails />)
              }
            </div>
          ))}
        </div>
      )} */}

    </>
  )
}

export default Servicedetails