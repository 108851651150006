import React from 'react';

function PublicAddressSystemDetails() {
  return (
    <>
      <div className="col-lg-12">
        <h2>Public Address System</h2>
        <p>
          The Public Address System is a crucial component of any facility or venue where announcements or audio broadcasting is required. It provides a means to communicate important messages, announcements, or emergency alerts to a large audience in a clear and effective manner.
        </p>
        <p>
          At JK Fire Protection Systems, we specialize in designing, installing, and maintaining high-quality Public Address Systems for various applications. Our systems are tailored to meet the specific needs of our clients, ensuring reliable and intelligible audio distribution throughout the designated areas.
        </p>
        <h3>Key Features</h3>
        <ul>
          <li><i className="bi bi-check-circle"></i> <span>Clear and intelligible audio reproduction.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Multiple zone support for flexible broadcasting.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Integration with other security systems.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Emergency announcement capabilities.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Volume control and equalization.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Remote control and monitoring options.</span></li>
        </ul>
        <h3>Benefits</h3>
        <ul>
          <li><i className="bi bi-check-circle"></i> <span>Enhanced communication and information dissemination.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Improved safety and emergency response.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Efficient crowd management.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Customizable and scalable solutions.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Integration with existing infrastructure.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Expert installation and maintenance services.</span></li>
        </ul>
        <h3>Our Approach</h3>
        <p>
          At JK Fire Protection Systems, we follow a comprehensive approach to deliver the best Public Address System solutions. Our process includes:
        </p>
        <ol>
          <li> <span>Requirement analysis and site survey.</span></li>
          <li> <span>System design and engineering.</span></li>
          <li> <span>Equipment selection and procurement.</span></li>
          <li> <span>Professional installation and testing.</span></li>
          <li> <span>System commissioning and training.</span></li>
          <li> <span>Ongoing maintenance and support.</span></li>
        </ol>
        <p>
          Our team of experts ensures that the Public Address System is seamlessly integrated with your existing infrastructure and meets all applicable standards and regulations.
        </p>
        <p>
          Contact us today to discuss your Public Address System requirements and let us provide you with a reliable and efficient solution tailored to your needs.
        </p>
      </div>

    </>
  );
}

export default PublicAddressSystemDetails;
