import React from 'react';
// import Breadcrumb from '../components/Breadcrumb';
import Team from '../components/Team';
import Client from '../components/Client';
import Whychoose from '../components/Whychoose';

function About() {
  return (
    <section>
      {/* <Breadcrumb /> */}
      
      <section id="about" className="about">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
            <div className="col-lg-6 position-relative align-self-start order-lg-last order-first">
              <img src="/assets/img/team/Jitender Kumar.jpg" className="img-fluids" alt="" />
              {/* <img src="/assets/img/team/Jitender Kumar.jpg" className="testimonial-img" alt="" /> */}
              <h3>Jitender Kumar</h3>
              <h4>Managing director</h4>
              {/* <h4>Ceo &amp; Founder</h4> */}
            </div>
            <div className="col-lg-6 content order-last order-lg-first">
              <h3>About Company:</h3>
              <p>
                At our company, we prioritize the safety and security of our customers by offering top-notch security products and services. Our clientele includes a diverse range of organizations, from large government agencies and high-risk power plants to secure chemical factories, private warehouses, educational institutions, and retail stores.
              </p>
              <h3>Mission:</h3>
              <p>
                Our mission is to align ourselves closely with our technology partners and customers, ensuring a clear focus on delivering cost-effective solutions and unwavering support without compromising quality. We are committed to providing high-quality, world-class service and have a strong passion for creating value for our customers.
              </p>
              <h3>Vision:</h3>
              <p>
                Our vision centers around harnessing the power of technology to provide exceptional value to our clients through seamless IT services and solutions. We strive to leverage technological advancements to deliver superior performance and meet the evolving needs of our clients.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Whychoose />

      <section className="about-section">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-6">
              <h3 className="mb-3">Our Approach</h3>
              <p className="mb-4">
                At JK Fire Protection Systems, we offer comprehensive solutions from conceptualization to implementation. Our expertise lies in integrating diverse products and functionalities into a unified infrastructure. With our knowledge in security, life safety/fire detection, communications, audio/visual, and networks, we are uniquely positioned to design, deliver, monitor, and service comprehensive solutions.
              </p>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-6">
              <h3 className="solution-title">Complete End-to-End Solution</h3>
              <ul className="solution-list">
                <li>
                  <strong>Pre Sales:</strong>
                  <ul>
                    <li>Site Survey</li>
                    <li>Detailed Functional Design Study</li>
                    <li>Process Engineering</li>
                  </ul>
                </li>
                <li>
                  <strong>Sales:</strong>
                  <ul>
                    <li>Designing BOM</li>
                    <li>Commercial</li>
                    <li>Delivery</li>
                    <li>Scheduling</li>
                  </ul>
                </li>
                <li>
                  <strong>Post Sales:</strong>
                  <ul>
                    <li>Installation</li>
                    <li>Testing</li>
                    <li>Commissioning</li>
                    <li>AMC (Annual Maintenance Contract)</li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="col-lg-6">
              <h3 className="industry-title">We Serve Various Industries</h3>
              <ul className="industry-list">
                <li>Corporate</li>
                <li>Pharmaceutical</li>
                <li>Banking sector</li>
                <li>Hospital</li>
                <li>Hotels</li>
                <li>Institution</li>
                <li>Retail</li>
                <li>Government</li>
                <li>Financial</li>
                <li>Residential</li>
                <li>And Many More...</li>
              </ul>
              <p>
                At JK Fire Protection Systems, we specialize in managing complexity with technical expertise, operating more efficiently with total integration, and ensuring professional project management. We follow standard operating practices and prioritize customer satisfaction.
              </p>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-6">
              <h3 className="expertise-title">Manage Complexity With Technical Expertise</h3>
              <ul className="expertise-list">
                <li>Operate More Efficiently With Total Integration</li>
                <li>Professional Project Management</li>
                <li>Standard Operating Practices</li>
                <li>Customer Satisfaction</li>
              </ul>
            </div>
            <div className="col-lg-6">
              <h3 className="solution-implementation-title">From Conceptualization to Implementation</h3>
              <p className="solution-implementation-description">
                Our customized solutions seamlessly integrate diverse products and functionalities into a unified infrastructure. We are uniquely positioned to design, deliver, monitor, and service comprehensive solutions that encompass security, life safety/fire detection, communications, audio/visual, networks, and more.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Client />
      <Team />
    </section>
  );
}

export default About;
