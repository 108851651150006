import React from 'react'
import Hero from '../components/Hero'
import Client from '../components/Client'
// import Counts from '../components/Counts'
// import Testimonials from '../components/Testimonials'
import Team from '../components/Team'
import Faq from '../components/Faq'
import Cta from '../components/Cta'
import Servicesection from '../components/Servicesection'
import Aboutsection from '../components/Aboutsection'
// import Portfolio from '../components/Portfolio'
import Gallery from '../components/Gallerys'
import Whychoose from '../components/Whychoose'

function Home() {
  return (
    <>  
        <Hero/>
        <Aboutsection/>
        <Whychoose />
        <Client/>
        {/* <Counts /> */}
        <Cta />
        <Servicesection displayAll={false} />
        <Team/>
        {/* <Testimonials/> */}
        {/* <Portfolio displayAll={false} /> */}
        <Gallery/>
        <Faq/>
    </>
  )
}

export default Home