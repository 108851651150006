import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

function Header() {
  const navLinks = [
    { title: 'Home', href: '/' },
    { title: 'About', href: '/about' },
    { title: 'Services', href: '/services' },
    { title: 'Contact', href: '/contact' },
    { title: 'Gallery', href: '/Gallery' },
  ];

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleMenuItemClick = () => {
    setIsMobileMenuOpen(false);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header id="header" className="header fixed-top">
      <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
        <NavLink to="/" className="logo d-flex align-items-center">
          <img src="/assets/img/lologo.png" alt="" />
          <span>JK Fire Protection Systems</span>
        </NavLink>

        <nav id="navbar" className={`navbar ${isMobileMenuOpen ? 'navbar-mobile' : ''}`}>
          <ul onClick={handleMenuItemClick}>
            {navLinks.map((link, index) => (
              <li key={index}>
                <NavLink className="nav-link scrollto" to={link.href}>
                  {link.title}
                </NavLink>
              </li>
            ))}
            {/* <li><a className="getstarted scrollto" href="/About">Get Started</a></li> */}
          </ul>
          <i className={`bi bi-list mobile-nav-toggle ${isMobileMenuOpen ? 'bi-x' : ''}`} onClick={toggleMobileMenu}></i>
        </nav>
      </div>
    </header>
  );
}

export default Header;
