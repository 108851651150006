import React from 'react';

function AVIPTelephoneDetails() {
  return (
    <>

      <div className="col-lg-12">
        <h2>Audio-Visual System</h2>
        <p>
          Our Audio-Visual system is designed to provide immersive audio and visual experiences in various settings, including boardrooms, conference rooms, auditoriums, and entertainment venues. We offer state-of-the-art audio equipment, high-resolution displays, and advanced control systems to enhance communication and engagement.
        </p>
        <ul>
          <li><i className="bi bi-check-circle"></i> <span>High-quality speakers and sound systems.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Large format displays and video walls.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Wireless presentation and collaboration tools.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Video conferencing solutions.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Integrated control systems for easy operation.</span></li>
        </ul>
        <p>
          Our experienced team will work closely with you to understand your specific requirements and design a customized Audio-Visual system that meets your needs and budget.
        </p>
      </div>
      <div className="col-lg-8">
        <h2>IP Telephone System</h2>
        <p>
          Our IP Telephone System leverages the power of Voice over Internet Protocol (VoIP) technology to provide reliable and cost-effective communication solutions. With our IP Telephone System, you can enjoy advanced features and seamless connectivity across your organization.
        </p>
        <ul>
          <li><i className="bi bi-check-circle"></i> <span>High-quality voice calls and audio clarity.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Unified communication and collaboration tools.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Flexible scalability to accommodate business growth.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Integration with other business applications.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Advanced call management and routing options.</span></li>
        </ul>
        <p>
          Whether you need a small office phone system or a complex enterprise solution, our team will design and implement an IP Telephone System that meets your specific communication needs.
        </p>
      </div>

    </>
  );
}

export default AVIPTelephoneDetails;
