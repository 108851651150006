import React from 'react';

function FireHydrantAndSprinklerSystems() {
  return (
    <>
      <div className="col-lg-12">
        <h3>Fire Hydrant Systems</h3>
        <p>
          Fire hydrant systems are an essential part of fire safety measures in buildings and industrial facilities. They provide a reliable source of water for firefighting operations. A typical fire hydrant system consists of underground water storage tanks, water pumps, pipes, and hydrant valves.
        </p>
        <p>
          The main components of a fire hydrant system include:
        </p>
        <ul>
          <li><i className="bi bi-check-circle"></i> <span>Underground Water Storage Tanks.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Fire Pumps.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Fire Hydrant Valves.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Water Pipes and Network.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Fire Hose Reels.</span></li>
        </ul>
        <p>
          Fire hydrant systems are designed to deliver high-pressure water flow to extinguish fires effectively. They are strategically placed throughout a building or facility to ensure easy access in case of an emergency. Regular maintenance and inspections are crucial to ensure the proper functioning of fire hydrant systems.
        </p>
      </div>
      {/* </div> */}

      {/* // <div className="row mt-5"> */}
      <div className="col-lg-8">
        <h2>Sprinkler Systems</h2>
        <p>
          Sprinkler systems are another vital component of fire protection systems. They are designed to detect and control fires automatically, providing early fire suppression and preventing the spread of flames. Sprinkler systems consist of a network of pipes with sprinkler heads installed at various locations.
        </p>
        <p>
          Key features of sprinkler systems include:
        </p>
        <ul>
          <li><i className="bi bi-check-circle"></i> <span>Sprinkler Heads.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Piping Network.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Control Valve.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Water Supply.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Alarm System.</span></li>
        </ul>
        <p>
          Sprinkler systems are activated when the heat from a fire reaches a specific temperature, causing individual sprinkler heads to release water directly onto the fire. This rapid response helps to control and suppress the fire, minimizing damage and protecting lives.
        </p>
      </div>
    </>
  );
}

export default FireHydrantAndSprinklerSystems;
