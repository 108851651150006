import React from 'react'

function Whychoose() {

    const customerReasons = [
        {
            titel: "Trusted and Professional Company",
            dec: "JK Fire Protection Systems has earned a strong reputation as a trusted and professional ELV services company in North India. With a track record of successful project execution, customers can rely on their expertise and quality services.",
        },
        {
            titel: "Comprehensive End-to-End Solutions",
            dec: "JK Fire Protection Systems offers complete end-to-end solutions, covering all stages of the project lifecycle. From pre-sales activities such as site surveys, detailed functional design studies, and process engineering, to sales processes including designing bills of materials (BOM), commercial aspects, delivery, and scheduling. They also provide post-sales services like installation, testing, commissioning, and AMC (Annual Maintenance Contract).",
        },
        {
            titel: "Technical Expertise",
            dec: "With a team of highly skilled professionals, JK Fire Protection Systems has the technical expertise to manage complex projects efficiently. They stay updated with the latest industry trends and technologies, ensuring that customers receive state-of-the-art solutions.",
        },
        {
            titel: "Total Integration",
            dec: "JK Fire Protection Systems helps customers operate more efficiently by providing total integration of their systems. They ensure that different components and technologies work seamlessly together, enhancing overall security and performance.",
        },
        {
            titel: "Professional Project Management",
            dec: "The company follows professional project management practices to ensure smooth execution, timely delivery, and cost-effective solutions. They have standard operating practices in place, ensuring consistency and quality throughout the project lifecycle.",
        },
        {
            titel: "Customer Satisfaction",
            dec: "JK Fire Protection Systems prioritizes customer satisfaction. They work closely with clients, understanding their specific needs and requirements, and delivering tailored solutions that meet or exceed expectations. Their commitment to customer satisfaction has resulted in a strong base of satisfied clients.",
        },
    ];

    return (
        <>

            <section id="why-us" className="why-us">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 d-flex align-items-stretch" data-aos="fade-right">
                            <div className="content">
                                <h3>Why Choose Us for your Security Service?</h3>
                                <p>
                                    JK Fire Protection Systems for your ELV services because we offer total integration, efficient project management, and a strong focus on customer satisfaction.
                                    {/* With a track record of over 55 projects and a dedicated team of 40+ employees, we provide end-to-end solutions, ensuring quality from pre-sales to post-sales. Trust our expertise and standard operating practices to meet your physical security needs effectively. */}
                                </p>
                                <div className="text-center" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                    <a href="#staticBackdrop" className="more-btn">Learn More <i className="bx bx-chevron-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 d-flex align-items-stretch">
                            <div className="icon-boxes d-flex flex-column justify-content-center">
                                <div className="row">
                                    <div className="col-xl-4 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                        <div className="icon-box mt-4 mt-xl-0">
                                            <i className="bx bx-receipt"></i>
                                            <h4>10 Years of Experience</h4>
                                            <p>With a decade of experience, we bring extensive knowledge and expertise to deliver exceptional solutions.</p>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="200">
                                        <div className="icon-box mt-4 mt-xl-0">
                                            <i className="bx bx-cube-alt"></i>
                                            <h4>100% Satisfaction</h4>
                                            <p>We are dedicated to achieving 100% customer satisfaction by delivering exceptional products and services that meet or exceed our clients' expectations.</p>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="300">
                                        <div className="icon-box mt-4 mt-xl-0">
                                            <i className="bx bx-images"></i>
                                            <h4>24/7 Security Service</h4>
                                            <p>
                                                We provide round-the-clock security services to ensure continuous protection and peace of mind for our clients.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="staticBackdropLabel">Why Choose Us</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="card-body">
                                    <h5 className="card-title">Customers choose JK Fire Protection Systems for their ELV (Extra Low Voltage) services due to the following reasons:</h5>
                                    <div className="accordion accordion-flush" id="accordionFlushExample">
                                        {customerReasons.map((reason, index) => (
                                            <div className="accordion-item" key={index}>
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${index}`} aria-expanded="false" aria-controls={`flush-collapse${index}`}>
                                                        <li style={{ listStyle: 'none' }}>
                                                            <i className="bi bi-check-circle"></i> <span> {reason.titel}</span>
                                                        </li>
                                                    </button>
                                                </h2>
                                                <div id={`flush-collapse${index}`} className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">{reason.dec}</div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <p className="card-text">In conclusion, customers choose JK Fire Protection Systems for their ELV services due to their trusted reputation, comprehensive end-to-end solutions, technical expertise, total integration capabilities, professional project management practices, and focus on customer satisfaction.</p>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Whychoose