import React from 'react'

function Aboutsection() {
  const listItems = [
    "Manage Complexity With Technical Expertise, ensuring efficient handling of intricate challenges.",
    "Experience seamless efficiency and improved operations through total integration.",
    "JK Fire Protection Systems is known for its professional management of projects, making them a reliable and efficient partner in the industry.",
    "Implementing Standard Operating Practices ensures consistent and efficient operations within an organization.",
    
  ]

  return (
    <>
      <section id="about" className="about">
      <div className="container">

        <div className="row">
          <div className="col-lg-6" data-aos="zoom-in">
            <img src="/assets/img/jk/about.jpg" className="img-fluid" style={{'height':'100%'}} alt=""/>
          </div>
          <div className="col-lg-6 d-flex flex-column justify-contents-center" data-aos="fade-left">
            <div className="content pt-4 pt-lg-0">
              <h3>Learn more about us</h3>
              <p className="fst-italic">
              JK Fire Protection Systems is a trusted and professional ELV Services Company in North India, with a well-deserved name in the industry, a total employee strength of 50+, and a successful track record of executing over 50+ projects.
              </p>
              <ul>
                {listItems.map((item, index) => (
                  <li key={index}><i className="bi bi-check-circle"></i> {item}</li>
                ))}
              </ul>
              <p>
              JK Fire Protection Systems works diligently to ensure customer satisfaction by providing top-notch services and solutions in the field of fire protection and security systems.
              </p>
            </div>
          </div>
        </div>

      </div>
    </section>   
    </>
  )
}

export default Aboutsection
