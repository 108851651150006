import React from 'react';
import { NavLink } from 'react-router-dom';

function Sidebar({ categories, activeCategory, setActiveCategory }) {
  return (
      <>
        {categories.map(category => (
          <NavLink to={`/Servicedetails/${category.name.toLowerCase().replace(/ /g, '-')}`}
            key={category.id}
            className={category.id === activeCategory ? 'active' : ''}
            onClick={() => setActiveCategory(category.id)}
          >
            {category.name}
          </NavLink>
        ))}
    </>
  );
}

export default Sidebar;
