import React from 'react'

import { useLocation } from 'react-router-dom';

function Breadcrumb() {

  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((pathname) => pathname);


  return (
    <>
      
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center" style={{ backgroundImage: "url('/assets/img/jk/breadcrumbs.jpg')" }}>
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 text-center">
                <h2>{pathnames[pathnames.length - 1]}</h2>
              </div>
            </div>
          </div>
        </div>
        {/* <nav>
          <div className="container">
            <ol>
              <li><NavLink to="/">Home</NavLink></li>
              {pathnames.map((pathname, index) => {
                const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
                const isCurrent = index === pathnames.length - 1;

                return (
                  <li key={index} className={`breadcrumb-item ${isCurrent ? 'active' : ''}`}>
                    {isCurrent ? (
                      <span aria-current="page">{pathname}</span>
                    ) : (
                      <NavLink to={routeTo}>{pathname}</NavLink>
                    )}
                  </li>
                );
              })}
            </ol>
          </div>
        </nav> */}
      </div>
    </>
  )
}

export default Breadcrumb