import React from 'react';

function AccessControlDetails() {
  return (
    <>
      
      <div className="col-lg-12" data-aos="fade-left">
        <div className="access-control-info">
          <h3>Access Control System</h3>
          <p>
            An Access Control System is a security solution that allows or restricts access to physical spaces, buildings, or areas within a facility. It ensures that only authorized individuals can enter specific areas, enhancing security and protecting assets.
          </p>
          <p>
            Key Features of our Access Control System:
          </p>
          <ul>
            <li><i className="bi bi-check-circle"></i> <span>Secure access through keycards, biometric data, or PIN codes.</span></li>
            <li><i className="bi bi-check-circle"></i> <span>Integration with video surveillance for enhanced security.</span></li>
            <li><i className="bi bi-check-circle"></i> <span>Centralized control and management of access rights.</span></li>
            <li><i className="bi bi-check-circle"></i> <span>Real-time monitoring and reporting.</span></li>
            <li><i className="bi bi-check-circle"></i> <span>Integration with other security systems like alarms and intercoms.</span></li>
          </ul>
          <p>
            Benefits of our Access Control System:
          </p>
          <ul>
            <li><i className="bi bi-check-circle"></i> <span>Enhanced security and protection against unauthorized access.</span></li>
            <li><i className="bi bi-check-circle"></i> <span>Improved operational efficiency by managing access rights easily.</span></li>
            <li><i className="bi bi-check-circle"></i> <span>Reduced reliance on physical keys and locks.</span></li>
            <li><i className="bi bi-check-circle"></i> <span>Increased accountability through access logs and audit trails.</span></li>
            <li><i className="bi bi-check-circle"></i> <span>Scalable solution to accommodate future growth and changes.</span></li>
          </ul>
        </div>
      </div>
    
    </>
  );
}

export default AccessControlDetails;
