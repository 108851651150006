import React from 'react';
function NetworkSecuritySystemDetails() {
  return (
    <>
     
            <div className="col-lg-12">
              <h2>Network Security System</h2>
              <p>
                Network security is essential to protect sensitive data and ensure the integrity and availability of network resources. Our Network Security System provides comprehensive solutions to safeguard your organization's network infrastructure from cyber threats.
              </p>
              <h3>Key Features:</h3>
              <ul>
                <li><i className="bi bi-check-circle"></i> <span>Firewall Protection: Our system includes robust firewall protection to monitor and control incoming and outgoing network traffic, preventing unauthorized access and potential attacks.</span></li>
                <li><i className="bi bi-check-circle"></i> <span>Intrusion Detection and Prevention: We employ advanced intrusion detection and prevention mechanisms to detect and mitigate any suspicious activities or malicious attempts to compromise your network.</span></li>
                <li><i className="bi bi-check-circle"></i> <span>Virtual Private Network (VPN): Our system supports secure VPN connections, allowing remote users to access your network resources securely over the internet.</span></li>
                <li><i className="bi bi-check-circle"></i> <span>Antivirus and Malware Protection: We integrate powerful antivirus and malware protection software into the network security system to detect and remove any malicious software that may pose a threat to your network.</span></li>
                <li><i className="bi bi-check-circle"></i> <span>Data Encryption: We implement strong encryption protocols to protect sensitive data during transmission, ensuring confidentiality and preventing unauthorized interception.</span></li>
                <li><i className="bi bi-check-circle"></i> <span>Access Control: Our system enables granular access control, allowing you to define and enforce user privileges, restricting unauthorized access to critical network resources.</span></li>
                <li><i className="bi bi-check-circle"></i> <span>Security Monitoring and Logging: We provide real-time monitoring and logging of network activities, enabling proactive threat detection and incident response.</span></li>
              </ul>
              <p>
                With our Network Security System, you can have peace of mind knowing that your network is protected against potential threats and vulnerabilities. Our experienced team will work closely with you to assess your specific security requirements and customize a solution that fits your organization's needs.
              </p>
            </div>
            
    </>
  );
}

export default NetworkSecuritySystemDetails;
