import React from 'react';
import Carousel from 'react-owl-carousel';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function Client() {
  const clientImages = [
    "/assets/img/clients/images1.jpg",
    "/assets/img/clients/images2.jpg",
    "/assets/img/clients/images3.jpg",
    "/assets/img/clients/images4.jpg",
    "/assets/img/clients/images5.jpg",
    "/assets/img/clients/images6.jpg",
    "/assets/img/clients/images7.jpg",
    "/assets/img/clients/images8.jpg",
    "/assets/img/clients/images9.jpg",
  ];

  const carouselOptions = {
    loop: true,
    // margin: 10,
    responsive: {
      320: {
        items: 1,
        margin: 40,
      },
      350: {
        items: 2,
        margin: 25,
      },
      640: {
        items: 3,
        margin: 60,
      },
      1000: {
        items: 5,
        margin: 100,
      }
    },
    autoplay: true, // Enable autoplay
    autoplayTimeout: 4000, // Autoplay interval in milliseconds
    autoplayHoverPause: true, // Pause autoplay on hover
    dots: true, // Show pagination dots
  };

//   Clint name list
// DELHI PUBLIC SCHOOL GHAZIABAD
// Shiv nadar school
// The British school
// Ingram micro private limited
// Rahman Industries Limited
// samkwang india electronic private limited
// ZEE ENTERAINMENT ENTERPRISES LIMITED
// CEASEFIRE INDUSTRIES PVT LTD
// Lupin Ltd


  return (
    <section id="clients" className="clients">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          {/* <span>Our Clients</span> */}
          <h2>Our Clients</h2>

        </div>
        <div className="clients-slider swiper">
          <div className="swiper-wrapper align-items-center">
           
            <Carousel className="owl-carousel" {...carouselOptions}>
              {clientImages.map((image, index) => (
                <div key={index} className="swiper-slide">
                  {/* <img src={image} className="img-fluid" alt="" /> */}
                  <table style={{ marginTop: '50px', float: 'left' }} border="0" width="100%" cellsspacing="10" cellPadding="10">
                    <tbody>
                      <tr>
                        <td style={{ textAlign: '-webkit-center'}}>
                          <div className="img_caption none" style={{ float: 'none', width: '125px' }}>
                            <img className="caption" style={{ border: '0', margin: '5px', boxShadow: '1px 1px 15px #cccccc',padding: '10px'}} 
                            title="Commercial" src={image} alt="Commercial" width="150" height="90" border="0" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ))}
            </Carousel>
          </div>
          {/* <div className="swiper-pagination"></div> */}
        </div>
      </div>
    </section>
    
  );
}

export default Client;
