import React from 'react';

function Faq() {

  const faqData = [

    {
      id: 1,
      question: "What makes your 24 / 7 security service different ?",
      answer: "Our 24 / 7 security service stands out due to our unwavering commitment to round - the - clock protection, ensuring continuous surveillance and immediate response to any security threats."
    },
    {
      id: 2,
      question: "What industries do you cater to with your security services ?",
      answer: "We provide security services to a wide range of industries, including corporate, pharmaceutical, banking sector, hospitals, hotels, institutions, retail, government, financial, residential, and many more."
    },
    {
      id: 3,
      question: "How experienced is your company in executing security projects ?",
      answer: "With a vast experience of successfully executing over 65+ projects, we have a proven track record in delivering reliable and effective security solutions."
    },
    {
      id: 4,
      question: "What is included in your end - to - end security solution ?",
      answer: "Our end - to - end security solution covers every aspect of the project, starting from pre - sales activities such as site surveys, detailed functional design studies, and process engineering, to sales processes including designing bills of materials(BOM), commercial aspects, delivery, and scheduling.We also offer post - sales services like installation, testing, commissioning, and AMC(Annual Maintenance Contract)."
    },
    {
      id: 5,
      question: "How do you ensure customer satisfaction ?",
      answer: "We prioritize customer satisfaction by working closely with our clients, understanding their specific needs and requirements, and delivering tailored security solutions that meet or exceed their expectations.Our professional project management practices and adherence to standard operating procedures further contribute to ensuring customer satisfaction."
    },
    {
      id: 6,
      question: "What is your approach to managing complexity in security projects ?",
      answer: "We manage complexity in security projects through our technical expertise, which enables us to handle intricate systems efficiently.With our total integration capabilities, we ensure seamless coordination among various security components, resulting in enhanced security and operational efficiency."
    },
    {
      id: 7,
      question: "How do you stay up - to - date with the latest industry trends and technologies?",
      answer: "We continuously invest in our team's professional development, ensuring they are well-versed in the latest industry trends and technologies. We actively participate in industry conferences, collaborate with technology partners, and stay updated with advancements in the security industry to provide state-of-the-art solutions to our clients."
    },
    {
      id: 8,
      question: "What is your approach to project management ?",
      answer: "We follow professional project management practices to ensure smooth execution, timely delivery, and cost - effective solutions.Our standardized operating practices ensure consistency and quality throughout the project lifecycle."
    },
    {
      id: 9,
      question: "How do I get started with your security services ?",
      answer: "To get started with our security services, you can contact our team through our website or by reaching out to our customer service representatives.We will be happy to discuss your specific security needs and provide you with a customized solution."
    }
  ];

  return (
    <>
      <section id="faq" className="faq section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            {/* <span>Frequently Asked Questions</span> */}
            <h2>Frequently Asked Questions</h2>

          </div>

          <div className="row justify-content-center" data-aos="fade-up" data-aos-delay="200">
            <div className="col-lg-10">

              <div className="accordion accordion-flush" id="faqlist">

                {faqData.map((faq) => (
                  <div className="accordion-item" key={faq.id}>
                    <h3 className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#faq-content-${faq.id}`}>
                        <i className="bi bi-question-circle question-icon"></i>
                        {faq.question}
                      </button>
                    </h3>
                    <div id={`faq-content-${faq.id}`} className="accordion-collapse collapse" data-bs-parent="#faqlist">
                      <div className="accordion-body">
                        {faq.answer}
                      </div>
                    </div>
                  </div>
                ))}

              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Faq;
