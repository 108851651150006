import React from 'react';

import Gallerys from '../components/Gallerys.jsx';
// import Breadcrumb from '../components/Breadcrumb.jsx';

function Gallery() {
  return (
    <section>
      {/* <Breadcrumb /> */}
      <Gallerys />
    </section>
  );
}

export default Gallery;

