import React from 'react';
function FM200Details() {
  return (
    <>

      <div className="col-lg-12">
        <h2>FM-200 Gas-Based Fire Suppression System</h2>
        <p>
          The FM-200 gas-based fire suppression system is a highly effective and reliable solution for protecting critical assets and sensitive areas. It uses FM-200, a clean agent gas that quickly extinguishes fires without leaving any residue or causing damage to equipment or valuable items.
        </p>
        <p>
          Key features and benefits of the FM-200 system include:
        </p>
        <ul>
          <li><i className="bi bi-check-circle"></i> <span>Fast and Effective Fire Suppression: FM-200 gas rapidly suppresses fires by reducing the oxygen levels in the protected area, effectively extinguishing flames and preventing re-ignition.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Safe for Occupants: FM-200 is a non-toxic and non-corrosive gas, ensuring the safety of people present in the protected space.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Environmentally Friendly: FM-200 is a clean agent that does not contribute to ozone depletion and has a short atmospheric lifetime.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Minimal Downtime: The FM-200 system activates quickly, minimizing downtime and reducing potential damage to critical equipment and valuable assets.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Flexible Design: The system can be designed to meet the specific needs of various applications and environments, including data centers, server rooms, control rooms, archives, and more.</span></li>
          <li><i className="bi bi-check-circle"></i> <span>Compliance with Regulations: FM-200 systems comply with international fire safety standards and regulations, providing peace of mind and ensuring legal requirements are met.</span></li>
        </ul>
      </div>

    </>
  );
}

export default FM200Details;
