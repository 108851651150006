import React from 'react'

function Cta() {
    return (
        <>
            <section id="cta" className="cta">
                <div className="container" data-aos="zoom-in">
                    <div className="text-center">
                        <h3 style={{color:"black"}}>Getting Assistence Call Our Security Team Any Situation</h3>
                        <p  style={{color:"black"}}> You can also fill out our online form if you are interested in learning more. We are ready to keep you and your business safe and secure.</p>
                        <a className="cta-btn" href="/Contact"  style={{color:"black"}}>Call To Action</a>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Cta