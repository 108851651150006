import React, { useState } from 'react';
import { NavLink } from "react-router-dom";

const services = [

  {
    id: 1,
    dp: '/assets/img/servicesdp/Surveillance System.jpg',
    className: 'blue',
    title: 'Surveillance System',
    description:
      'A surveillance system is essential for monitoring and ensuring the security of premises. It offers real-time video footage, advanced analytics, and remote access, enabling effective surveillance and prompt response to potential threats.',
  },
  {
    id: 2,
    dp: '/assets/img/servicesdp/Public Address System.jpg',
    className: 'blue',
    title: 'Public Address System',
    description:
      'A Public Address System is an essential audio communication tool used in various settings, allowing clear and effective broadcasting of messages, announcements, and music to a large audience.',
  },
  {
    id: 3,
    dp: '/assets/img/servicesdp/Fire Safety and Security System.png',
    className: 'blue',
    title: 'Fire Safety and Security System',
    description:
      'Fire Safety and Security Systems are crucial for protecting lives and property. By integrating advanced technology, these systems provide early detection, prompt response, and effective mitigation of fire-related risks, ensuring a safer environment.',
  },
  {
    id: 4,
    dp: '/assets/img/servicesdp/Access control.jpg',
    className: 'blue',
    title: 'Access control System',
    description:
      'An Access Control System ensures secure access to facilities while efficiently tracking employee attendance, enhancing security measures, and simplifying administration processes.',
  },
  {
    id: 5,
    dp: '/assets/img/servicesdp/Audio-Visual and IP Telephone System.jpg',
    className: 'blue',
    title: 'Audio-Visual and IP Telephone System',
    description:
      ' Audio-Visual and IP Telephone System: Our integrated solutions seamlessly combine high-quality audio-visual components and advanced IP telephony for enhanced communication, collaboration, and immersive multimedia experiences in any setting.',
  },
  {
    id: 6,
    dp: '/assets/img/servicesdp/Fire hydrants and Sprinklers systems.PNG',
    className: 'blue',
    title: 'Fire hydrants and Sprinklers systems',
    description:
      'TechArt provides customizable fire suppression systems, including pre-engineered and tailor-made options with minimal distribution pipe.',
  },
  {
    id: 7,
    dp: '/assets/img/servicesdp/FM-200 gas based Fire Suppression systems.PNG',
    className: 'blue',
    title: 'FM-200 gas based Fire Suppression systems',
    description:
      'A fire hydrant is a connection point by which firefighters can tap into a water supply. It is a component of active fire protection.',
  },

  {
    id: 8,
    dp: '/assets/img/servicesdp/Visitor Management System.png',
    className: 'blue',
    title: 'Visitor Management System',
    description:
      'A Visitor Management System is an essential tool for efficiently managing and monitoring visitors in various settings, ensuring enhanced security, streamlined check-ins, and accurate visitor data records.',
  },
  {
    id: 9,
    dp: '/assets/img/servicesdp/Network Security System.jpg',
    className: 'blue',
    title: 'Network Security System',
    description:
      "A robust network security system is essential in today's digital landscape to safeguard sensitive data and protect against cyber threats, ensuring a secure and reliable network infrastructure.",
  },
  // {
  //   id: 10,
  //   className: 'blue',
  //   title: '',
  //   description:
  //     '',
  // },
];

function Servicesection({ displayAll }) {
  const [cardCount, setCardCount] = useState(displayAll ? services.length : 3);
  const displayedCards = services.slice(0, cardCount);

  const showMoreButton =
    cardCount < services.length ? (
      <div data-aos-delay="400">
        <div className="text-center justify-content-center ">
          {/* <button onClick={() => setCardCount(cardCount + 3)} className="cta-btn">
            <span>Show More</span>
          </button> */}
          <button>
            <NavLink to="/services">Show More</NavLink>
          </button>
        </div>
      </div>
    ) : null;

  return (
    <section id="services" className="services">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          {/* <span>Our Services</span> */}
          <h2>Our Services</h2>

        </div>

        <div className="row gy-4">
          {displayedCards.map((service, index) => (
            <div
              key={index}
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              // data-aos-delay={(index + 1) * 100}
              data-aos-delay={100}
            >
              <div className={`service-box ${service.className}`}>
                {/* <i className="ri-discuss-line icon"></i> */}
                <i><img style={{ width: '18.5rem', marginBottom: '1rem' }} src={service.dp} alt='' /></i>
                <h3>{service.title}</h3>
                <p>{service.description}</p>
                <NavLink to={`/Servicedetails/${service.title.toLowerCase().replace(/ /g, '-')}`} >
                  <button>
                    <span>Read More</span> <i className="bi bi-arrow-right"></i>
                  </button>
                </NavLink>
              </div>
            </div>
          ))}
          {showMoreButton}
        </div>
      </div>
    </section>
  );
}

export default Servicesection;