const contexts = require.context('../public/assets/img/gallery/', true, /\/.+$/);
const fileNames = contexts.keys().map(key => key.slice(1));

// export default folderNames;

// const context = require.context('../public/assets/img/gallery/', true, /\/.+$/);
// const folderNames = new Set(context.keys().map(key => key.slice(2)));

// export default folderNames;


const context = require.context('../public/assets/img/gallery', true, /\/.+$/); // Note the false flag to exclude sub-folders
const folderNames = context.keys().map(key => {
  const folderPath = key.slice(2); // Remove the './' from the key path
  return folderPath.split('/')[0]; // Get only the first part of the path (top-level folder name)
});

export const folderNamesSet = new Set(folderNames);
export default fileNames;



