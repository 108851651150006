import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import fileNames from '../model';
import Breadcrumb from './Breadcrumb';
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css'; // Import the styles for the modal
import 'react-image-lightbox/style.css'; // Import the styles for the lightbox

function GalleryDetails() {
  const [files, setFiles] = useState([]);
  const [lightboxIndex, setLightboxIndex] = useState(-1); // Initialize with -1 to keep the modal closed
  const { folderName } = useParams();

  useEffect(() => {
    // Filter the fileNames array to include only the files from the selected folder
    const filteredFiles = fileNames.filter(fileName => {
      return fileName.startsWith(`/${folderName}/`);
    });

    console.log(filteredFiles);

    setFiles(filteredFiles);
  }, [folderName]);

  const openModal = (index) => {
    setLightboxIndex(index);
  };

  const closeModal = () => {
    setLightboxIndex(-1);
  };

  const navigateToNext = () => {
    setLightboxIndex((lightboxIndex + 1) % files.length);
  };

  const navigateToPrevious = () => {
    setLightboxIndex((lightboxIndex + files.length - 1) % files.length);
  };

  return (
    <>
      <Breadcrumb />

      <div className="inner-content">
        <div className="inner-content-wrapper">
          <div className="innerpage-container">
            <h3 className="general-h3" style={{ marginTop: '0' }}>{folderName} Project Gallery</h3>
            <div className="photo-gallery-images-components">
              {files.map((fileName, index) => (
                <NavLink
                  key={index}
                  onClick={() => openModal(index)} // Open the modal on click
                  style={{ backgroundImage: `url(/assets/img/gallery/${fileName})` }}
                  className="example-image-link"
                  data-lightbox="example-set"
                  data-title="GAT-Hostel"
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Modal component */}
      {lightboxIndex >= 0 && (
        <Modal open={true} onClose={closeModal} center classNames={{ modal: 'custom-modal' }}>
          <div className="lightbox-content" style={{
            display: 'flex' ,alignItems: 'center'}}>
          <i className="fas fa-chevron-left" onClick={navigateToPrevious}></i>
          <div className="image-container">
            <img
              src={`/assets/img/gallery/${files[lightboxIndex]}`}
              alt="Modal Image"
              className="lightbox-image"
            />
          </div>
          <i className="fas fa-chevron-right" onClick={navigateToNext}></i>

        </div>
        </Modal >
      )
}
    </>
  );
}

export default GalleryDetails;
