import React from 'react';

function FireSafetyAndSecuritySystem() {
  return (
    <>
            <div className="col-lg-12" data-aos="fade-left">
              {/* <div className="content"> */}
                <h2>Fire Safety and Security System</h2>
                <p>
                  At JK Fire Protection Systems, we understand the importance of fire safety and security for your premises. Our comprehensive fire safety and security system is designed to protect your property, assets, and most importantly, the lives of your occupants.
                </p>
                <p>
                  Our system includes state-of-the-art fire detection and alarm systems, CCTV surveillance systems, access control systems, and integrated security solutions. With our expertise and experience, we ensure that your premises are equipped with the latest technologies and meet all safety standards and regulations.
                </p>
                <p>
                  Our team of professionals will assess your specific requirements and provide a customized solution tailored to your needs. We handle the entire process, from design and installation to testing, commissioning, and maintenance. Our aim is to provide you with a reliable and efficient fire safety and security system that gives you peace of mind.
                </p>
                
            </div>
    </>
  );
}

export default FireSafetyAndSecuritySystem;
