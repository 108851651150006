import React, { useRef } from 'react'
import { Input, TextArea } from 'semantic-ui-react';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
// import Breadcrumb from '../components/Breadcrumb'

const SERVICE_ID = "Your_SERVICE_ID";
const TEMPLATE_ID = "Your_TEMPLATE_ID";
const USER_ID = "Your_USER_ID";

function Contact() {

    const form = useRef();

    const handleOnSubmit = (e) => {
        e.preventDefault();
        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
            .then((result) => {
                console.log(result.text);
                Swal.fire({
                    icon: 'success',
                    title: 'Message Sent Successfully'
                })
            }, (error) => {
                console.log(error.text);
                Swal.fire({
                    icon: 'error',
                    title: 'Ooops, something went wrong',
                    // text: error.text,
                    text: 'Please Contact directly on given contact number.'
                })
            });
        e.target.reset()
    };

    return (
        <>
            {/* <Breadcrumb /> */}
            <section id="contact" className="contact">

                <div className="container" data-aos="fade-up">

                    <div className="section-header">
                        {/* <span>Contact</span> */}
                        <h2>Contact</h2>

                    </div>

                    <div>
                        <iframe  
                            className="mb-3"
                            title="Google Maps Embed"
                            style={{ border: 0, width: "100%", height: "340px" }}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.189045737148!2d77.34219548225624!3d28.62409571293447!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce50ac78dba5d%3A0x683e08ddabc1124b!2sKhora%20colony!5e0!3m2!1sen!2sin!4v1688556429919!5m2!1sen!2sin"
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        />
                    </div>

                    <div className="row gy-4">

                        <div className="col-lg-6">

                            <div className="row gy-4">
                                <div className="col-md-6">
                                    <div className="info-box">
                                        <i className="bi bi-geo-alt"></i>
                                        <h3>Address</h3>
                                        <p>RC 437/2 Kala enclave khora colony,<br /> ghaziabad, Uttar Pradesh, IN 201309</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="info-box">
                                        <i className="bi bi-telephone"></i>
                                        <h3>Call Us</h3>
                                        <p>+91-9818755113<br />+91-8585998726</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="info-box">
                                        <i className="bi bi-envelope"></i>
                                        <h3>Email Us</h3>
                                        <p>Info@jkfps.com</p>
                                        <a href="mailto:Info@jkfps.com" target='_blank' rel="noreferrer">Send a message</a>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="info-box">
                                        <i className="bi bi-clock"></i>
                                        <h3>Working Hours</h3>
                                        <p>Monday - Saturday<br />9:00AM - 05:00PM</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-lg-6">
                            <form ref={form} onSubmit={handleOnSubmit} className="php-email-form">
                                <div className="row gy-4">

                                    <div className="col-md-6">
                                        <input id='form-input-control-last-name'
                                            control={Input}
                                            label='Name'
                                            name='user_name'
                                            required
                                            icon='user circle' type="text" className="form-control" placeholder="Your Name" />
                                    </div>

                                    <div className="col-md-6 ">
                                        <input type="email" className="form-control" name="user_email" id='form-input-control-email'
                                            control={Input} icon='mail' placeholder="Your Email" required />
                                    </div>

                                    <div className="col-md-12">
                                        <input type="text" className="form-control" name="subject" placeholder="Subject" required />
                                    </div>

                                    <div className="col-md-12">
                                        <textarea id='form-textarea-control-opinion'
                                            control={TextArea}
                                            label='Message'
                                            name='user_message' className="form-control" rows="6" placeholder="Message" required></textarea>
                                    </div>

                                    <div className="col-md-12 text-center">
                                        <div className="loading">Loading</div>
                                        <div className="error-message"></div>
                                        <div className="sent-message">Your message has been sent. Thank you!</div>

                                        <button type="submit">Send Message</button>
                                    </div>

                                </div>
                            </form>


                        </div>

                    </div>

                </div>

            </section>
        </>
    )
}

export default Contact